//ENUM DE MESSAGENS DE ERRO RETORNADAS PELA STONE

const DEFAULT_ERRO = 1
const TRANSACTION_NOT_ALLOWED = 1019
const CONTACT_CARD_CENTER = 1828
const INVALID_INSTALLMENT = 1805
const NOT_ALLOWED_CARD = 1836
const CARD_ERROR = 1820
const INVALID_ESTABLISHMENT = 1009
const TRANSACTION_AMOUNT_NOT_ALLOWED = 1010
const INVALID_CARD_DATA = 1838
const NOT_ALLOWED = 2008
const CONTACT_YOUR_CARD_CENTER = 3002
const ESTABLISHMENT_CONTACT = 1040
const TRANSACTION_N_ALLOWED = 9102

export {
    DEFAULT_ERRO,
    TRANSACTION_NOT_ALLOWED, 
    CONTACT_CARD_CENTER,
    INVALID_INSTALLMENT,
    NOT_ALLOWED_CARD ,
    CARD_ERROR,
    INVALID_ESTABLISHMENT,
    TRANSACTION_AMOUNT_NOT_ALLOWED,
    INVALID_CARD_DATA,
    NOT_ALLOWED,
    CONTACT_YOUR_CARD_CENTER,
    ESTABLISHMENT_CONTACT,
    TRANSACTION_N_ALLOWED
}