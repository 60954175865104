const SUCCESS_RESULT = 1
const LINK_EXPIRED_RESULT = 2
const ERROR_RESULT = 3
const NOT_REGISTERED_RESULT = 4
const PAYMENT_IN_ANALYSIS = 5
const PAYMENT_DECLINED = 6
const PAYMENT_WAITING_PIN_VALIDATION = 7 
const PIN_VALIDATED = 8
const ERROR_CONFIG_CNPJ = 9
const ERROR_LINK = 10
const PAYMENT_APPROVED = 11
const BLOCKED_LINK = 12
const PAYMENT_SETTLED = 13

export {
    SUCCESS_RESULT,
    LINK_EXPIRED_RESULT,
    ERROR_RESULT,
    NOT_REGISTERED_RESULT,
    PAYMENT_IN_ANALYSIS,
    PAYMENT_DECLINED,
    PAYMENT_WAITING_PIN_VALIDATION,
    PIN_VALIDATED,
    ERROR_CONFIG_CNPJ,
    ERROR_LINK,
    PAYMENT_APPROVED,
    BLOCKED_LINK,
    PAYMENT_SETTLED
}