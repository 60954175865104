const OPEN_STATUS = 1
const PAID_STATUS = 2
const EXPIRED_STATUS = 3
const MISSING_REGISTRY_BANKSLIP_CNPJ_STATUS = 50

export {
    OPEN_STATUS,
    PAID_STATUS,
    EXPIRED_STATUS,
    MISSING_REGISTRY_BANKSLIP_CNPJ_STATUS
}
