import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock, faTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faCheckCircle, faArrowCircleRight, faUserCircle } from '@fortawesome/free-solid-svg-icons'
import { SUCCESS_RESULT, LINK_EXPIRED_RESULT, ERROR_RESULT, PAYMENT_IN_ANALYSIS, PAYMENT_WAITING_PIN_VALIDATION, PAYMENT_DECLINED, ERROR_LINK, ERROR_CONFIG_CNPJ, PAYMENT_SETTLED, PIN_VALIDATED, BLOCKED_LINK, PAYMENT_APPROVED } from '../../../models/paymentResultType'
import { getImgLogoCardResult } from '../../../utils/paymentCardUtil'
import './index.css'

export default class PaymentResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showSuccessModal: this.props.type === SUCCESS_RESULT,
            copyToClipboardButtonText: "COPIAR",
            choosedQuestion: ""
        };
    }

    getPaymentResultData = () => {
        const { type, checkoutConfig, receiptEmail, securityValidation } = this.props

        const expiredLinkMessage = checkoutConfig?.expiredLinkMessage
        const showExpiredLinkMail = checkoutConfig?.showExpiredLinkMail
        const showExpiredLinkPhone = checkoutConfig?.showExpiredLinkPhone
        const securityValidationStep = securityValidation?.step ?? null


        switch (type) {
            case LINK_EXPIRED_RESULT:
                return {
                    title: expiredLinkMessage,
                    subtitle: showExpiredLinkPhone || showExpiredLinkMail ? 'Para gerar um novo link, contate nossa central:' : '',
                    icon: faClock,
                    iconColor: '#FFB526'
                }
            case PAYMENT_WAITING_PIN_VALIDATION:
                return {
                    title: 'Seu pagamento está em análise.',
                    subtitle: 'Recebemos seu pagamento e a liquidação da sua dívida está em processamento.' + (receiptEmail ? ' Em breve, você receberá o comprovante da liquidação através do e-mail: ' : ''),
                    icon: faClock,
                    iconColor: '#25D366'
                }
            case PIN_VALIDATED:
                return {
                    title: 'Obrigado pela validação do PIN!',
                    subtitle: 'Estamos finalizando a liquidação da sua dívida' + (receiptEmail ? ' e, assim que concluída, você receberá o comprovante através do e-mail: ' : '.'),
                    icon: faCheckCircle,
                    iconColor: '#25D366'
                }
            case PAYMENT_DECLINED:
                return {
                    title: 'Infelizmente não foi possível concluir seu pagamento.',
                    subtitle: 'Mas não se preocupe, o estorno do valor cobrado já foi solicitado e seu limite será liberado em até duas faturas.'
                    + '\nQualquer dúvida, favor entrar em contato com o emissor do seu cartão de crédito.',
                    icon: faTimesCircle,
                    iconColor: '#FD4646'
                }
            case PAYMENT_IN_ANALYSIS:
                return {
                    title: 'Seu pagamento está em análise.',
                    subtitle: 'Recebemos seu pagamento e a liquidação da sua dívida está em processamento.' + (receiptEmail ? ' Em breve, você receberá o comprovante da liquidação através do e-mail: ' : ''),
                    icon: faClock,
                    iconColor: '#25D366'
                }
            case PAYMENT_APPROVED:
                return {
                    title: 'Seu pagamento foi aprovado.',
                    subtitle: 'Parabéns! Seu pagamento foi aprovado e sua dívida será liquidada!' + (receiptEmail ? ' O comprovante da liquidação foi enviado para o email:' : ''),
                    icon: faCheckCircle,
                    iconColor: '#25D366'
                }
            case PAYMENT_SETTLED:
                return {
                    title: 'Seu pagamento foi aprovado.',
                    subtitle: 'Sua dívida foi liquidada' + (receiptEmail ? ' e o comprovante da liquidação foi enviado para o email:' : '.'),
                    icon: faCheckCircle,
                    iconColor: '#25D366'
                }
            case ERROR_RESULT:
                return {
                    title: 'Seu link está em processamento',
                    subtitle: 'Por favor, aguarde e tente novamente em breve.',
                    icon: this.getSvgIcon(ERROR_RESULT),
                    iconType: 'svg',
                    iconColor: '#FD4646'
                }
            case ERROR_CONFIG_CNPJ:
                return {
                    title: 'Identificamos um erro no cadastro do CNPJ do link.',
                    subtitle: 'Por favor, entre em contato para corrigi-lo.',
                    icon: faTimesCircle,
                    iconColor: '#FD4646'
                }
            case ERROR_LINK:
                return {
                    title: 'Ocorreu um erro ao obter os dados do link.',
                    subtitle: 'Por favor, verfique se o endereço foi inserido corretamente.',
                    icon: faTimesCircle,
                    iconColor: '#FD4646'
                }
            case BLOCKED_LINK:
                return {
                    title: 'Verificação de segurança',
                    subtitle: '',
                    icon: faUserCircle,
                    iconColor: '#5F9EA0'
                }
            default:
                break;
        }
    }

    getSvgIcon(type) {
        switch (type) {
            case ERROR_RESULT:
                return <svg width="101" height="87" viewBox="0 0 101 87" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M100.202 52.6579C100.202 49.4068 99.4744 46.4305 98.1562 43.6832C96.6752 39.7239 94.4306 36.0994 91.5518 33.0186C88.673 29.9379 85.2171 27.4619 81.3835 25.7337C80.2984 19.4868 77.2746 13.7475 72.7471 9.34105C66.5653 3.11368 59.0653 0 50.2017 0C43.0198 0 36.5653 2.1521 30.8835 6.54789C25.2017 10.9437 21.4289 16.5758 19.5198 23.5816C13.838 24.8637 9.11075 27.84 5.51984 32.5105C1.92893 37.1811 0.20166 42.4926 0.20166 48.4453C0.20166 55.3595 2.6562 61.2663 7.51984 66.0742C12.4289 70.9737 18.3835 73.2632 25.2017 73.2632H42.2926C48.0653 81.5511 57.5653 87 68.3835 87C85.9744 87 100.202 72.6679 100.202 54.9474V52.6579ZM25.2017 64.1053C20.7926 64.1053 17.0653 62.5484 13.9744 59.5263C10.838 56.2753 9.29257 52.5205 9.29257 48.0789C9.29257 43.6374 10.838 39.8826 13.9744 36.7689C17.0653 33.6095 20.7926 32.0526 25.2017 32.0526H27.4744C27.4744 25.7337 29.7471 20.3305 34.1108 15.8432C38.5653 11.4474 43.9289 9.1579 50.2017 9.1579C56.4744 9.1579 61.838 11.4474 66.2926 15.8432C68.3835 17.9953 69.9744 20.4221 71.0653 23.0321C70.2017 22.8947 69.2926 22.8947 68.3835 22.8947C50.7926 22.8947 36.5653 37.2268 36.5653 54.9474C36.5653 58.1526 37.0653 61.2205 37.8835 64.1053H25.2017ZM68.3835 77.8421C55.838 77.8421 45.6562 67.5853 45.6562 54.9474C45.6562 42.3095 55.838 32.0526 68.3835 32.0526C80.9289 32.0526 91.1107 42.3095 91.1107 54.9474C91.1107 67.5853 80.9289 77.8421 68.3835 77.8421ZM70.6562 56.0921L83.6562 63.8305L80.2471 69.4168L63.838 59.5263V36.6316H70.6562V56.0921Z" fill="#3DE096"/></svg>
            default:
                break;
        }
    }

    emailMask(email) {
        return email.replace(/^(.{3})(.*)(@.*)$/,
            (_, a, b, c) => a + b.replace(/./g, '*') + c
        );
    }

    takeToUrl(url) {
        window.open(url, '_blank');
    }

    handleRadioChange = (event) => {
        this.setState({ choosedQuestion: event.target.value })
    }

    render() {
        const {
            type,
            receiptEmail,
            paymentValidations,
            checkoutConfig,
            erroMenssageCredor,
            securityValidation,
            securityButtonDisabled,
            securityRadioReset
        } = this.props

        const paymentData = this.getPaymentResultData()
        const showExpiredLinkPhone = checkoutConfig?.showExpiredLinkPhone
        const showExpiredLinkMail = checkoutConfig?.showExpiredLinkMail
        const expiredLinkMail = checkoutConfig?.expiredLinkMail 
        const expiredLinkPhone = checkoutConfig?.expiredLinkPhone
        const erroMenssage = erroMenssageCredor? erroMenssageCredor : 'Agradecemos sua compreensão.'


        return (
            <>
                <div className="box-result">
                    <div className="text-center ml-2 mr-2">
                        {paymentData.iconType === 'svg'? 
                            <>
                                {paymentData.icon}
                            </> 
                            :
                            <>
                                <h1><FontAwesomeIcon icon={paymentData.icon} size="2x" color={paymentData.iconColor} /></h1> 
                            </>
                        }
                        <div className="result-title">{paymentData.title}</div>
                        <div className={`result-sub-title ${type === PAYMENT_DECLINED ? 'payment-declined-line-spacing' : ''}`}>{paymentData.subtitle}</div>
                        {type === PAYMENT_SETTLED &&
                            <>
                            {receiptEmail && <div className="result-sub-title"><b>{this.emailMask(receiptEmail)}</b></div>}
                            <div className="result-sub-title payment-declined-line-spacing font-weight-bold">Qualquer dúvida, você pode entrar em contato pelo Whatsapp através do número (11) 4302-6166.
                                <br />
                                Obrigado! Equipe Quita+
                            </div>
                                <div className="result-sub-title mt-2 mt-2 font-weight-bold">Seu pagamento poderá aparecer na fatura do seu cartão de crédito nos seguintes formatos de exemplo: "QUITA*123", "6 x QUITA*123" ou "Parc=110QUITA*123".</div>
                            </>
                        }
                        {type === PAYMENT_APPROVED &&
                            <>
                                {receiptEmail && <div className="result-sub-title"><b>{this.emailMask(receiptEmail)}</b></div>}
                                <div className="result-sub-title mt-2 mt-2 font-weight-bold">Seu pagamento poderá aparecer na fatura do seu cartão de crédito nos seguintes formatos de exemplo: "QUITA*123", "6 x QUITA*123" ou "Parc=110QUITA*123".</div>
                            </>
                        }
                        {type === PIN_VALIDATED &&
                            <>
                                {receiptEmail && <div className="result-sub-title"><b>{this.emailMask(receiptEmail)}</b></div>}
                                <div className="result-sub-title mt-2 mt-2">Caso não seja possível concluir a liquidação da sua dívida, seu pagamento será estornado e o valor estará disponível em até duas faturas no seu cartão de crédito.</div>
                                <div className="result-sub-title mt-2 mt-2 font-weight-bold">Seu pagamento poderá aparecer na fatura do seu cartão de crédito nos seguintes formatos de exemplo: "QUITA*123", "6 x QUITA*123" ou "Parc=110QUITA*123".</div>
                            </>
                        }
                        {type === ERROR_RESULT && <div className="result-sub-title" >{erroMenssage}</div>}
                        {type === PAYMENT_IN_ANALYSIS && 
                            <>
                                {receiptEmail && <div className="result-sub-title"><b>{this.emailMask(receiptEmail)}</b></div>}
                                <div className="result-sub-title mt-2 mt-2">Nossa equipe poderá entrar em contato para a validação de alguns dados.</div>
                                <div className="result-sub-title mt-2 mt-2 font-weight-bold">Seu pagamento poderá aparecer na fatura do seu cartão de crédito nos seguintes formatos de exemplo: "QUITA*123", "6 x QUITA*123" ou "Parc=110QUITA*123".</div>
                            </>
                        }
                        {/* LINK_EXPIRED_RESULT */}
                        {type === LINK_EXPIRED_RESULT && <div className="result-sub-title mt-2">{showExpiredLinkPhone ? expiredLinkPhone : ''}<br/>{showExpiredLinkMail ? expiredLinkMail : ''}</div>}
                        {/* PAYMENT_IN_PROCESS */}
                        {type === PAYMENT_WAITING_PIN_VALIDATION &&
                            <>
                                {receiptEmail && <div className="result-sub-title"><b>{this.emailMask(receiptEmail)}</b></div>}
                                <div className="result-sub-title mt-2 mt-2">
                                    Agora, o próximo passo é validar o código PIN para cada cartão utilizado. O código PIN é um valor de 3 dígitos disponível na fatura dos cartões utilizados.
                                    {paymentValidations.length <= 1 && <div className="know-more" style={{ paddingTop: '6px' }}><a onClick={() => this.takeToUrl(paymentValidations[0].url)}>Saiba como</a><FontAwesomeIcon icon={faArrowCircleRight} /></div>}
                                </div>

                                {paymentValidations.length > 1 ?
                                    <>
                                        <div className='result-pin'>
                                            <div className='result-pin-sub-title'>Clique em cada cartão para validar o PIN</div>
                                            <div className='box-buttons-pin'>
                                                {paymentValidations.map(i => {
                                                    return (
                                                        <button type='button' className='btn btn-default btn-pin multiple-cards' target="_blank" onClick={() => this.takeToUrl(i.url)}>
                                                            {getImgLogoCardResult(i.cardBrand)} <div style={{ width: '100%' }}>{i.cardBrand}<span className='ml-2 mr-2'>****</span>{i.truncatedPan.slice(-4)}</div>
                                                        </button>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <button type='button' className='btn btn-default btn-pin' target="_blank" onClick={() => this.takeToUrl(paymentValidations[0].url)}>VALIDAR PIN AGORA</button>
                                    </>
                                }
                            <div className="result-sub-title credit-card-bill-info">Seu pagamento poderá aparecer na fatura do seu cartão de crédito nos seguintes formatos de exemplo: "QUITA*123", "6 x QUITA*123" ou "Parc=110QUITA*123".</div>
                            </>
                        }
                        {type === BLOCKED_LINK &&
                            <>                                                     
                                <div className="teste">
                                <div className="mt-2 mt-2 mb-2">{securityValidation.question}</div>
                                    <form>
                                        <div className="radio">
                                            <label>
                                                <input className="securityInput" name="securityOption" type="radio" value={securityValidation.answers[0]} onChange={this.handleRadioChange} checked={!securityRadioReset && this.state.choosedQuestion === securityValidation.answers[0]} />
                                                {securityValidation.answers[0]}
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input className="securityInput" name="securityOption" type="radio" value={securityValidation.answers[1]} onChange={this.handleRadioChange} checked={!securityRadioReset && this.state.choosedQuestion === securityValidation.answers[1]} />
                                                {securityValidation.answers[1]}
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input className="securityInput" name="securityOption" type="radio" value={securityValidation.answers[2]} onChange={this.handleRadioChange} checked={!securityRadioReset && this.state.choosedQuestion === securityValidation.answers[2]}  />
                                                {securityValidation.answers[2]}
                                            </label>
                                        </div>
                                        <div className="radio">
                                            <label>
                                                <input className="securityInput" name="securityOption" type="radio" value={securityValidation.answers[3]} onChange={this.handleRadioChange} checked={!securityRadioReset && this.state.choosedQuestion === securityValidation.answers[3]} />
                                                {securityValidation.answers[3]}
                                            </label>
                                        </div>
                                        <div className="radio">
                                        <label>
                                                <input className="securityInput" name="securityOption" type="radio" value={securityValidation.answers[4]} onChange={this.handleRadioChange} checked={!securityRadioReset && this.state.choosedQuestion === securityValidation.answers[4]} />
                                                {securityValidation.answers[4]}
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            
                            {securityButtonDisabled && <button className={`btn btn-default envBt`} style={{ width: "50%" }}type="submit" disabled>
                                <span className="spinner-border" role="status" aria-hidden="true"></span>
                                &nbsp;Enviar
                            </button>}
                            {!securityButtonDisabled && <button className="btn btn-default envBtn" type="submit" onClick={() => this.props.validate(this.state.choosedQuestion)} >Enviar</button>}
                            </>
                        }
                    </div>
                </div>
            </>
        )
    }
}
