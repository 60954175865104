import React, { Component } from 'react'
import { Route } from 'react-router'
import { Layout } from './pages/Layout'
import { Payment } from './pages/Payment/index.jsx'
import { DebtCollectionPayment } from './pages/DebtCollectionPayment/index.jsx'

import './custom.css'

export default class App extends Component {
  render() {
    return (
      <Layout>
        <Route exact path='/:partnerName/:paymentLinkGuid' component={Payment} />
        <Route exact path='/easylink/:partnerName/:paymentLinkGuid' component={DebtCollectionPayment} />
      </Layout>
    )
  }
}