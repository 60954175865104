const BLOCKED_STATUS = 1
const BLOCKED_EXPIRED_STATUS = 2
const OPEN_STATUS = 3
const EXPIRED_STATUS = 4
const UNDER_ANALYSIS_STATUS = 5
const WAITING_PIN_VALIDATION_STATUS = 6
const UNDER_ANALYSIS_VALIDATED_PIN_STATUS = 7
const APPROVED_STATUS = 8
const DENIED_STATUS = 9
const PAID_STATUS = 10

export {
    BLOCKED_STATUS,
    BLOCKED_EXPIRED_STATUS,
    OPEN_STATUS,
    EXPIRED_STATUS,
    UNDER_ANALYSIS_STATUS,
    WAITING_PIN_VALIDATION_STATUS,
    UNDER_ANALYSIS_VALIDATED_PIN_STATUS,
    APPROVED_STATUS,
    DENIED_STATUS,
    PAID_STATUS
}