export const BANKS_LIST = ['BANCO',
                            'ITAU',
                            'CAIXA', 
                            'ECONOMICA',    
                            'FEDERAL',
                            'BRADESCO',
                            'BNDES',
                            'SANTANDER',
                            'HSBC',
                            'SAFRA',
                            'BTG', 
                            'PACTUAL',
                            'VOTORAANTIM',
                            'CITIBANK',
                            'BANRISUL',
                            'MORGAN', 
                            'CHASE',
                            'CREDIT', 
                            'SUISSE',
                            'NORDESTE', 
                            'BRASIL',
                            'DEUTSCHE',
                            'BMG',
                            'VOLKSVAGEM',
                            'COOPERATIVO', 
                            'SICREDI',
                            'PANAMERICANO',
                            'BANRISUL',
                            'EXTRA', 
                            'ITAUCARD',
                            'VISA', 
                            'SIGNATURE',
                            'PLATINUM',
                            'MASTER',
                            'CARD',
                            'VISA',
                            'MASTERCARD'
                           ];
