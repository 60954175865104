import { getAntiForgeryToken } from '../antiforgery'

async function getCheckoutSetup(partnerName) {
    try {
        const response = await fetch(`checkout/getCheckoutSetup/${partnerName}`)

        return await response.json()
    } catch (error) {
        throw error
    }
}

async function getDebtCollectionData(paymentLinkGuid) {
    try {
        const response = await fetch(`checkoutDebtCollection/${paymentLinkGuid}`)

        return await response.json()
    } catch (error) {
        throw error
    }
}

async function pay(paymentLinkGuid, settlementRequest) {
    try {
        const response = await fetch(`checkoutDebtCollection/${paymentLinkGuid}/pay`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getAntiForgeryToken(),
            },
            body: JSON.stringify(settlementRequest)
        })

        return await response.json()
    } catch (error) {
        throw error
    }
}

async function validate(paymentLinkGuid, validateRequest) {
    try {
        const response = await fetch(`checkoutDebtCollection/payerValidation/${paymentLinkGuid}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getAntiForgeryToken(),
            },
            body: JSON.stringify(validateRequest)
        })         

        return await response.status

    } catch (error) {
        throw error
    }
}


export {
    getCheckoutSetup,
    getDebtCollectionData,
    pay,
    validate,
}
