import React, { Component } from 'react'
import quitaMaisLogo from '../../assets/images/footer/quita-mais-white-logo.png'
import mastercardLogo from '../../assets/images/footer/mastercard.png'
import visaLogo from '../../assets/images/footer/visa.png'
import amexLogo from '../../assets/images/footer/amex.png'
import eloLogo from '../../assets/images/footer/elo.png'
import hipercardLogo from '../../assets/images/footer/hipercard.png'
import { toCpfOrCnpj } from '../../utils/cpfCnpjFormatter'
import './index.css'
import { LINK_EXPIRED_RESULT } from '../../models/paymentResultType'

export default class Footer extends Component {
    render() {
        const { checkoutConfig, paymentResultType } = this.props
        const partnerMail = checkoutConfig?.mail 
        const partnerPhone = checkoutConfig?.phone

        const options = {
            hasThreeDomainSecure: checkoutConfig? checkoutConfig.hasThreeDomainSecure : false,
            title: checkoutConfig? checkoutConfig.title : 'Cappta',
            document: checkoutConfig? checkoutConfig.document : '13966572000171'
        }

        return (
            <>
                <footer className='navbar-fixed-bottom'>  
                    <div className='container cont-footer'>
                        <div className='row' style={{ flexWrap: 'nowrap' }}>
                            {(paymentResultType !== LINK_EXPIRED_RESULT) &&
                                <div className='col col-text'>
                                    <div className='col-text-1'>Para dúvidas ou suporte, entre em contato com a nossa central</div>
                                    {partnerPhone && <div className='col-text-1'>{partnerPhone}</div>}
                                    {partnerMail && <div className='col-text-1'>{partnerMail}</div>}
                                </div>
                            }
                            <div className='col col-cards'>
                                <div className='col-cards-1'>
                                    <div className='col-cards-title'>Pagamento</div>
                                    <div className='col-cards-imgs'>
                                        <img src={mastercardLogo} alt="Logo Mastercard" title="Mastercard" className="mr-2 mb-2 card-brand-logo" />
                                        <img src={visaLogo} alt="Logo Visa" title="Visa" className="mr-2 mb-2 card-brand-logo" />
                                        {!options.hasThreeDomainSecure && <>
                                            <img src={eloLogo} alt="Logo Elo" title="Elo" className="mr-2 mb-2 card-brand-logo" />
                                            <img src={hipercardLogo} alt="Logo Hipercard" title="Hipercard" className="mr-2 mb-2 card-brand-logo" />
                                        </>}
                                    </div>
                                </div>
                                <div className='col-logo-pja'>
                                    <img src={quitaMaisLogo} alt="Logo Quita+ by Cappta" title="Quita+ by Cappta" className="quita-mais-logo" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-container-2">
                        <div className='div-disclaimer-itau'><span className='disclaimer-itau'>A Cappta é um correspondente bancário do Itaú nos termos da resolução 3.954/2011, do conselho Monetário Nacional.</span></div>
                        <span className='disclaimer-cnpj'>Cappta - CNPJ: 13.966.572/0001-71 - Todos os direitos reservados.</span>
                    </div>
                </footer>
            </>
        )
    }
}