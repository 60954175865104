import React from 'react'

import visa from '../assets/images/cards/visa.png'
import mastercard from '../assets/images/cards/mastercard.png'
import hipercard from '../assets/images/cards/hipercard.png'
import amex from '../assets/images/cards/amex.png'
import elo from '../assets/images/cards/elo.png'
import defaultImg from '../assets/images/cards/default.png'

import logoDefault from '../assets/images/navbar/logo.png'

export function getCardType(number) {

  // Mastercard 
  // Updated for Mastercard 2017 BINs expansion
  if (/^5[1-5]/.test(number)) { return "mastercard" }

  // visa
  var re = new RegExp("^4[0-9]{15}")
  if (number.match(re) != null) { return "visa" }

  // Visa Electron
  re = new RegExp("^(4026|417500|4508|4844|491(3|7))")
  if (number.match(re) != null) { return "visa" }

  //HiperCard
  var re = new RegExp("^606282|^3841(?:[0|4|6]{1})0")
  if (number.match(re) != null) { return "HiperCard" }

  // AMEX
  re = new RegExp("^3[47][0-9]{13}$")
  if (number.match(re) != null) { return "amex" }

  // elo
  re = new RegExp(/^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/)
  if (number.match(re) != null) { return "elo" }

  return "default"
}

export function getImgLogoCard(cardBrand) {

  var img
  const className = 'img-card-brand'
  const alt = 'Bandeira do cartão de crédito'
  switch (cardBrand) {
    case 'visa':
    case 'Visa':
      img = <img src={visa} className={className} alt={alt} />
      break;
    case 'mastercard':
    case 'Mastercard':
      img = <img src={mastercard} className={className} alt={alt} />
      break;
    case 'HiperCard':
    case 'hipercard':
      img = <img src={hipercard} className={className} alt={alt} />
      break;
    case 'amex':
    case 'Amex':
      img = <img src={amex} className={className} alt={alt} />
      break;
    case 'elo':
    case 'Elo':
      img = <img src={elo} className={className} alt={alt} />
      break;
    default:
      img = <img src={defaultImg} className={className} alt={alt} />
  }

  return img
}

export function getImgLogoCardResult(cardBrand) {
  const className = 'img-card-brand'
  const alt = 'Bandeira do cartão de crédito'

  if(cardBrand === 'Visa' || cardBrand === 'Visa'){
    return <img src={visa} className={className} alt={alt} />

  } else if(cardBrand === 'Mastercard' || cardBrand === 'mastercard'){
    return <img src={mastercard} className={className} alt={alt} />

  } else if(cardBrand === 'HiperCard' || cardBrand === 'hipercard'){
    return <img src={hipercard} className={className} alt={alt} />

  } else if(cardBrand === 'Amex' || cardBrand === 'amex'){
    return <img src={amex} className={className} alt={alt} />

  } else if(cardBrand === 'Elo' || cardBrand === 'elo'){
    return <img src={elo} className={className} alt={alt} />

  } else {
    return ''
  }
}

export function returnNameCard(cardBrand){
  return cardBrand === 'default'? '' : cardBrand
}

export function getLogoNavbar(checkoutConfig) {

  if (checkoutConfig) {
    if (checkoutConfig.logoUrl)
      return <img src={checkoutConfig.logoUrl} alt="logo" className="d-md-inline-block align-top" style={{ maxWidth: `152px` }}></img>
    else
      return <img src={logoDefault} alt="logo" className="d-md-inline-block align-top" style={{ width: `152px` }}></img>
  } else {
    return <img src={logoDefault} alt="logo" className="d-md-inline-block align-top" style={{ width: `152px` }}></img>
  }
}