import React, { Component } from 'react'
import './index.css'
import { getLogoNavbar } from '../../utils/paymentCardUtil'

export default class NavMenu extends Component {

  constructor(props) {
    super(props)

    this.toggleNavbar = this.toggleNavbar.bind(this)
    this.state = {
      collapsed: true
    }
  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  render() {
    const { checkoutConfig } = this.props

    const options = {
      headerColor: checkoutConfig ? checkoutConfig.headerColor : '#25D366',
    }

    return (
      <>
        <nav className="navbar" style={{ background: options.headerColor }}>
          <a className="navbar-brand" href="#">
            {getLogoNavbar(checkoutConfig)}
          </a>
        </nav>
      </>
    )
  }
}