import { getAntiForgeryToken } from '../antiforgery'

async function tdsAuthenticate(tdsAuthenticationRequest) {
    const response = await fetch(`tds/authenticate`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': await getAntiForgeryToken(),
        },
        body: JSON.stringify(tdsAuthenticationRequest)
    })

    return await response.json()
}

async function getTdsResult(escapedUniqueTransactionId, quotationId) {
    try {
        const response = await fetch(`tds/result/${encodeURIComponent(escapedUniqueTransactionId)}/${quotationId}`)

        return await response.json()
    } catch (error) {
        return null
    }
}


export {
    tdsAuthenticate,
    getTdsResult
}
