const Undefined = 0
const UnderAnalysis = 1
const Declined = 2
const Approved = 3

export {
    Undefined,
    UnderAnalysis,
    Declined,
    Approved
}