import { getAntiForgeryToken } from '../antiforgery'

async function getCheckoutSetup(partnerName) {
    try {
        const response = await fetch(`checkout/getCheckoutSetup/${partnerName}`)

        return await response.json()
    } catch (error) {
        throw error
    }
}

async function getPaymentData(paymentLinkGuid, partnerName) {
    try {
        const response = await fetch(`checkout/${paymentLinkGuid}/${partnerName}`)

        return await response.json()
    } catch (error) {
        throw error
    }
}

async function settleQuotation(paymentLinkGuid, settlementRequest) {
    try {
        const response = await fetch(`checkout/${paymentLinkGuid}/settle`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getAntiForgeryToken(),
            },
            body: JSON.stringify(settlementRequest)
        })

        return await response.json()
    } catch (error) {
        throw error
    }
}

async function settleAutomaticValidation(paymentLinkGuid, quotationValues) {
    try {
        const response = await fetch(`checkout/${paymentLinkGuid}/UpsertAutomaticValidation`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': await getAntiForgeryToken(),
            },
            body: JSON.stringify(quotationValues)
        })

        return await response.json()
    } catch (error) {
        throw error
    }
}

export {
    getCheckoutSetup,
    getPaymentData,
    settleQuotation,
    settleAutomaticValidation
}
